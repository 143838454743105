import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
const cloneDeep = require('clone-deep');
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private messageSector = new BehaviorSubject('');
  private setSector = new BehaviorSubject('');
  currentSector = this.messageSector.asObservable();
  settSector = this.setSector.asObservable();
  httpOptions = {
    headers: new HttpHeaders(),
  };
  apiUrl:string;
  gate2bUrl:string;
  // formDataSelected;
  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.API_END_POINT;
    this.gate2bUrl=environment.API_GATE2B_END_POINT;
   }
  changeMessage(sector: string) {
    this.messageSector.next(sector);
  }
  setMessage(sector: string) {
    this.setSector.next(sector);
  }
  getSectorsData() {
    return this.httpClient.get(this.gate2bUrl+'sixAPI/getSectors');
  }
  createBiller(data){
    return this.httpClient.post(this.gate2bUrl+'sixAPI/createBiller',data);
  }
  updateBiller(data){
    return this.httpClient.post(this.gate2bUrl+'sixAPI/updateBiller',data);
  }
  updateAsset(data){
    return this.httpClient.post(this.gate2bUrl+'sixAPI/updateAsset',data);
  }
  getBillerDetails(data){
    return this.httpClient.post(this.gate2bUrl+'sixAPI/getBillerDetails',data);
  }
  fetchAsset(data){
    return this.httpClient.post(this.gate2bUrl+'sixAPI/getAsset',data);
  }
  fetchBiller(data){
    return this.httpClient.post(this.gate2bUrl+'sixAPI/getBiller',data);
  }
  // setFormData(data){
  //   this.formDataSelected=cloneDeep(data);
  //   return this.formDataSelected;
  // }
}
