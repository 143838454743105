import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { FieldWrapper } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SettingsService } from './settings.service';
import { I18nService } from '@app/shared/i18n';
import * as moment from 'moment';
@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <label style="color:#ffff;font-size:13px;">{{'ebill_Sector' |i18n}} *</label>
    <ng-select [(ngModel)]="selectedSector" [multiple]="true" class="companySelect" placeholder="{{'ebill_Sector' |i18n}}" (change)="sectorChange($event)" (blur)="checkSector(selectedSector)" [ngClass]="{'requiredSection':isRequired}">
    <ng-option *ngFor="let sec of sectorData" [value]="sec.id">{{sec.name}}</ng-option>    
    </ng-select>
    <div *ngIf="isRequired" class="errorDiv"><small class="requiredSector">{{'common_required_field'|i18n}}</small></div> 
  `,
  styleUrls:['./settings.component.scss']
})
export class PanelWrapperComponent extends FieldWrapper implements OnInit {
  sectorData=[];
  lanId;
  lngData={
    "en":"eng",
    "de":"ger",
    "fr":"fre",
    "it":"ita"
  }
  selectedSector:any;
  langChangeSubscription: any;
  isRequired:boolean=false;
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;  
  constructor(private settingsService:SettingsService,private commonService: CommonService,private i18nService: I18nService){
    super();
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
  }
  ngOnInit(){
    this.getSector();
    this.settingsService.settSector.subscribe(message => {
      if (message != "") {
        this.selectedSector = message;
      }
      else {
        return;
      }
    });    
  }
  onLangChange(langObj) {
    this.lanId = this.commonService.getCurrentLanguageCode();
    moment.locale(this.lanId);
    this.getSector();
    // this.loadTable = false;
    setTimeout(() => {
      // this.getContactList();
    }, 1000);
    langObj.isHandled = true;
  }
  ngOnDestroy() {
    this.langChangeSubscription ? this.langChangeSubscription.unsubscribe() : "";
  }
  sectorChange(eve){ 
    if(eve!=undefined && eve.length==0){
      this.isRequired=true;
    }
    else{
      this.isRequired=false;
    }
    this.settingsService.changeMessage(eve); 
  }
  checkSector(eve){
    if(eve==undefined  || eve.length==0){
      this.isRequired=true;
    }
    else{
      this.isRequired=false;
    }
  }
  getSector(){
    this.lanId = this.commonService.getCurrentLanguageCode();
    this.settingsService.getSectorsData()
    .subscribe(sectorItems=>{
      if(sectorItems['status']){
       sectorItems['data'].forEach(it=>{
        let d={id:it["id"],name:it['localizedData'][this.lngData[this.lanId]].name}
        this.sectorData.push(d);
       });      
      }    
    });
    // if(this.selectedSector!=undefined){

    // }
  }
}