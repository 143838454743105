import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

import { Store } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import * as crypto from 'crypto-browserify'
declare var Buffer: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  encryptionKey: string;
  excludeTokenVerification =[]
  constructor(
    private injector: Injector,
    public authToken: AuthTokenService,
    public router: Router,
    public store: Store<fromAuth.AuthState>
  ) { 
    this.encryptionKey = environment.encryptionKey;
    this.excludeTokenVerification= [`${environment.API_END_POINT}/api/v1/auth/authlogin`]
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   
      request = request.clone({
        withCredentials: true
      });
      request = this.addHeader(request);
    let tempMethod = request.url.split('/');
    let method = tempMethod[tempMethod.length-1]
    if(localStorage.getEncryptedItem('isBexioCompany')=='false' && !['getToken','getAccessToken','authorization','user-profile'].includes(method)) {
      this.router.navigate(['/login'])
    }
    if (request.url.search(environment.API_END_POINT) === 0) {
      // attach token

      return this.handleApiRequest(request, next);
    } else {
      return next.handle(request);
    }
  }

  private addHeader(req: HttpRequest<any>) { 
    const logParams = {}; 
    if (req.body instanceof FormData) {
      if (!req.body.has('partyIdLog')) {
        req.body.append('partyIdLog', localStorage.getEncryptedItem('partyID'));
      }
      if (!req.body.has('accIdLog')) {
        req.body.append('accIdLog', localStorage.getEncryptedItem('accId'));
      }
      req =  req.clone({
        body: req.body
      })
    } else {
      // const formData = {}; 
      // // formData['partyId'] = localStorage.getEncryptedItem('partyID');
      // // formData['accId'] = localStorage.getEncryptedItem('accId');
      // req =  req.clone({
      //   body: {...req.body, ...formData}
      // })
    } 
    return req.clone({
      headers: new HttpHeaders(),
      withCredentials: true
    });
  }
  handleApiRequest(request, next) {    
    // let request1 = request.clone({ headers: request.headers.set('withCredentals', 'true') })
    const handler = next.handle(request).pipe(
      catchError((error, caught) => {
        if (error.status === 401 ) {
          window.location.href = window.origin+'/';  
          // window.location.href=environment.LOGIN_URL;
          // this.store.dispatch(new fromAuth.LogoutAction());
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
  encrypt(data) {

    var binaryEncryptionKey = new Buffer( this.encryptionKey, "base64" );
    var binaryIV = new Buffer( 0 );

    var cipher = crypto.createCipheriv( "AES-128-ECB", binaryEncryptionKey, binaryIV );
    var encryptedInput = (
     cipher.update( data, "utf8", "base64" ) +
     cipher.final( "base64" )
    );
    return encryptedInput;

  }
}
