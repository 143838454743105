import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {languages} from '../languages.model'
import {I18nService} from "../i18n.service";
import { Subscription } from 'rxjs';
import { EmitService } from '@app/ts/services/emit.service';
import { CommonService } from '@app/core/services/common.service';
import { environment } from '@env/environment';

declare var $: any;

@Component({
  selector: 'sa-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls:['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {

  public languages: Array<any>;
  public currentLanguage: any;
  reload:boolean=true;
  loadedinConstructor=false;
  langSubscription: Subscription;
  languageListData = languages;
  @ViewChild('languageList') languageList: ElementRef;
  getStatus = false;

  constructor(private i18n: I18nService,private emitService:EmitService,private commonService: CommonService) {
    this.langSubscription=this.emitService.langLoginChange.subscribe(data=>{
      data == null ? data = 'de':'';
      let userLang = this.languageListData.filter( a => a.key == data);
      this.loadedinConstructor=true;      
      this.currentLanguage = userLang[0];
      this.currentLanguage.key=data;
      this.setLanguage(this.currentLanguage);
      this.reload=true;
    });
    $(document).on('click',(event)=>{ 
      var x = this.languageList.nativeElement;
      if (x.style.display === "block" && !this.getStatus){
        x.style.display = "none"; 
      }
      this.getStatus = false;
    });
  }

  ngOnInit() {
    let languageCookie = this.commonService.getCookie(this.commonService.encrypt(environment.languageCookie))
    // let lang =  this.commonService.getCookie(this.commonService.encrypt('languageCookie'));
    let defaultLang = this.commonService.decrypt(languageCookie)
    // $(".dropdown-menu").hide();
    if(!this.loadedinConstructor){
      let currentLang = defaultLang?JSON.parse(defaultLang) : localStorage.getEncryptedItem("current_lang") ? JSON.parse(localStorage.getEncryptedItem("current_lang")) : {};
      // let currentLang = localStorage.getEncryptedItem("current_lang") ? JSON.parse(localStorage.getEncryptedItem("current_lang")) : {};
      let language = Object.keys(currentLang).length === 0 && currentLang.constructor === Object ?  this.i18n.currentLanguage : currentLang;
      this.setLanguage(language);
    }
  }
  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }
  public setLanguage(language){
    this.reload=false;
    this.languages =  this.i18n.setLanguageData(language.key);
    this.currentLanguage = language;
    this.i18n.setLanguage(language);
    this.reload=true;
    // this.commonService.setCookie(this.commonService.encrypt(environment.languageCookie),this.commonService.encrypt(JSON.stringify(language)),10000,".io-procurement.com",'/')
    // language ? this.commonService.setCookie(this.commonService.encrypt(environment.languageCookie),this.commonService.encrypt(typeof(language)=='string' ?language:JSON.stringify(language)),10000,".io-procurement.com",'/'):'';
    this.commonService.setCookie(this.commonService.encrypt(environment.languageCookie),this.commonService.encrypt(JSON.stringify(language)),10000,environment.cookieDomain,'/')
  }

  showList(e) {
    $(".language-list").toggle();
    this.getStatus = true;
  }
}
