import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
const cloneDeep = require('clone-deep');
@Injectable({
  providedIn: 'root'
})
export class BillerCertificateService {
  @Output() messageSector: EventEmitter<any> = new EventEmitter();
  @Output() setSector: EventEmitter<any> = new EventEmitter();
  // private messageSector = new BehaviorSubject('');
  // private setSector = new BehaviorSubject('');
  // currentSector = this.messageSector.asObservable();
  // settSector = this.setSector.asObservable();
  httpOptions = {
    headers: new HttpHeaders(),
  };
  accountsUrl:string;
  gate2bApiUrl:string;
  // formDataSelected;
  constructor(private httpClient: HttpClient) {
    this.accountsUrl = environment.API_END_POINT;
  
    this.gate2bApiUrl=environment.API_GATE2B_END_POINT;
   }
   changeMessage(sector: any) {
    this.messageSector.emit(sector);
  }
  setMessage(sector: any) {
    this.setSector.emit(sector);
  }
  getSectorsData() {
    // return this.httpClient.get('http://192.168.2.55:5000/api/v1/sixAPICertificate/getSectors');
    return this.httpClient.get(this.gate2bApiUrl+'sixAPICertificate/getSectors');

  }
  createBiller(data){
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/createBiller',data);
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/createBiller',data);
  }
  updateBiller(data){
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/updateBiller',data);
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/updateBiller',data);
  }
  updateAsset(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/updateAsset',data);
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/updateAsset',data);
  }
  getBillerDetails(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/getBillerDetails',data);
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/getBillerDetails',data);

  }
  fetchAsset(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/getAsset',data);
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/getAsset',data);
  }
  fetchBiller(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/getBiller',data);
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/getBiller',data);

  }
  getDocumentFormatList(data){
    return this.httpClient.post(this.gate2bApiUrl+'gate2bAPI/gate2B/post-list/document-format/',data);
  }
  downloadFile(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT+'gate2bAPI/downloadFile',data);
  }
  getDocumentTypeList(data){
    return this.httpClient.post(this.gate2bApiUrl+'gate2bAPI/gate2B/document-type', data);
  }
  getInterfaceList(netcom_id){
    return this.httpClient.post(this.gate2bApiUrl+'gate2bAPI/gate2B/netcomid-interface',netcom_id);
  }  
  getCompanyDetails(data){
    return this.httpClient.post(environment.API_END_POINT_ADMIN +`admin/company-details`,data);
  }
  getebillCounts(data){
    return this.httpClient.post<any[]>(this.gate2bApiUrl+'gate2bAPI/gate2B/exists',data);
  }
  getAutherizationStatus(data){
    return this.httpClient.post(this.accountsUrl+'gate2bAPI/getAutherizationStatus', data);

  }
  setAutherizationStatus(data){
    return this.httpClient.post(this.accountsUrl+'gate2bAPI/setAutherizationStatus', data);
  }
  searchBiller(data){
    // return this.httpClient.post('http://192.168.2.60:8080/api/v1/sixAPICertificate/searchBiller',data);
    return this.httpClient.post(this.gate2bApiUrl+'sixAPICertificate/searchBiller',data);
  }
  updatePartyService(data) {
    return this.httpClient.post(this.gate2bApiUrl + 'gate2bAPI/partyServiceUpdate', data);
  }

  notifyEbillMail(data) {
    return this.httpClient.post(environment.API_END_POINT+'gate2bAPI/notifyEbillMail',data);
  }
  updateInfoData(data){
    return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/updateInfo",data)
  }
  getInfoData(data){
    return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/infoList",data)
  }
  activeBillers(data){
    return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/activeBillers",data);
    // return this.httpClient.post("http://192.168.2.55:5000/api/v1/gate2bAPI/activeBillers",data);
   
  }
  changePayementComments(data){
     return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/changePayementComments",data);
     // return this.httpClient.post("http://192.168.2.47:3000/api/v1/gate2bAPI/changePayementComments",data);
   
  }
  getPayementComments(data){
   return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/getPayementComments",data);
    //  return this.httpClient.post("http://192.168.2.47:3000/api/v1/gate2bAPI/getPayementComments",data);
   
  }
}
