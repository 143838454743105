import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { EmitService } from '@app/ts/services/emit.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private appService : AppService,private commonService:CommonService,private emitService: EmitService,private http: HttpClient) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if( route.url[0].path == 'ebill' ) this.checkisEbilldisable();
    let cookieExist = this.commonService.getCookie(environment.checkCookie);
    if (cookieExist == "") {
      this.router.navigate(['/login'])
    }
    else if(localStorage.getEncryptedItem('isBexioCompany')=='true') {
      let expiredCookie = this.commonService.decrypt(decodeURIComponent(cookieExist))
      if (this.tokenExpired(expiredCookie)) {
        // token expired
        this.appService.createAccessToken().subscribe(data=>{ });
      }
      if( !localStorage.getEncryptedItem('userData') ){
        let formData = new FormData();
        formData.append('code','BEXIO');
        formData.append('accId',localStorage.getEncryptedItem('accId'));
        formData.append('partyID',localStorage.getEncryptedItem('partyID'));
        let objectUser = this.commonService.createObject(formData);
        let tokenUser = this.commonService.createToken(objectUser);
        let encryptedTokenUser = this.commonService.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser); 
               
        let tempPath = window.location.hash.replace('#/','') == '' ? 'edi' : route.url[0].path;
        tempPath = (tempPath == 'ebill' && localStorage.getEncryptedItem('hasBiller') == 'true') ? tempPath+'/invoices' : tempPath;
        this.http.post(environment.API_ACCOUNTS_END_POINT+'users/user-profile',encrDataUser).subscribe(dataFromApi => {
          let user = dataFromApi['data'];
          let permissions = this.commonService.encrypt(String(user[0].permissions.Allow));
          this.commonService.setCookie(environment.permissions,permissions,10000,environment.cookieDomain,'/');
          // localStorage.setEncryptedItem('permissions',permissions);
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
          localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty.toString());
          // localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty.toString());
          localStorage.setEncryptedItem('hasPermission',user[0].hasPermission.toString());
          localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial.toString());
          let userName = user[0].username;
          let companyList = user[0].companyDetails;
          let companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
          let userInfo = {};
          userInfo["userName"] = userName;
          userInfo["companyName"] = companyName;
          setTimeout(() => {
            let language = user[0].language ? user[0].language : 'de';
            this.emitService.emitLoginLanguageChange(language);
          },10);
          this.commonService.setCookie(this.commonService.encrypt(environment.userInfo),this.commonService.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
          let permissionCookie = this.commonService.getCookie(environment.permissions);
          if(this.commonService.decrypt(permissionCookie).indexOf(route.data.right)==-1){
            window.location.href = environment.profile_URL;
            return 
          }
          // this.appService.notify('true');
          window.location.href = environment.BEXIO_URL + tempPath ;
        });
      } 
      else{
        let permissionCookie = this.commonService.getCookie(environment.permissions);
        if(this.commonService.decrypt(permissionCookie).indexOf(route.data.right)==-1){          
          if(route.data.pageTitle=='Ebill'){
            window.location.href = environment.BEXIO_URL+'ebill';          }
          else{
            window.location.href = window.origin+'/#/login/logout';
          }
        }        
        return true;
      }
    }
    // return true;
  }

  private tokenExpired(token) {
    let cookieExpiredTime = JSON.parse(token)
    return new Date(cookieExpiredTime[environment.tokenExpireTime]).getTime() < new Date().getTime();
  }
  checkisEbilldisable(){
      let showEbill =  localStorage.getEncryptedItem('showEbill') ? (localStorage.getEncryptedItem('showEbill') == 'true') : false;
      if( !showEbill &&  window.location.href.includes('ebill') && !window.location.href.includes('ebill/biller')){
        this.router.navigate(['ebill/biller']);
      }
  }
  
}
