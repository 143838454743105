import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
 
  httpOptions = {
    headers: new HttpHeaders(),
  };

  apiUrl:string;
  gate2bApiUrl:string;
  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.API_END_POINT;
    this.gate2bApiUrl=environment.API_GATE2B_END_POINT;
   }

   getToken(data) {
     return this.httpClient.post(this.apiUrl+'gate2bAPI/getToken',data);
   }

   getAccessToken(data) {
    return this.httpClient.post(this.apiUrl+'gate2bAPI/getAccessToken',data);
   }
   getBillerDetails(data){
    // return this.httpClient.post('http://192.168.2.55:5000/api/v1/sixAPICertificate/getBillerDetails',data);
    return this.httpClient.post(this.apiUrl+'sixAPICertificate/getBillerDetails',data);

  }
}
