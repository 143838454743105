import { Pipe, PipeTransform } from '@angular/core';
// import { CommonService } from '@app/shared/common/common.service';

@Pipe({
  name: 'numberFormat'
})
export class NumberPipe implements PipeTransform {
  rgx = /^[0-9]*\.?[0-9]*$/;
  transform(value: any, lan:string, num:boolean): any {
    if( isNaN(value)){
      return value
    }
    else{
      if(String(value).match(this.rgx)){
          if(num)
          	return new Intl.NumberFormat('de-CH').format(value)
          else
         	 return new Intl.NumberFormat('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
      }
      else 
        return value;
    }    
  }
}
