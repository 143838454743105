import { Validators } from '@angular/forms';
import { ReflectiveInjector, Injector } from "@angular/core";
import { I18nService } from "../../i18n/i18n.service";
import { ConfigOption } from '@ngx-formly/core';
import { PanelWrapperComponent } from '@app/features/ebill/settings/panel-wrapper.component';
import { certificatePanelWrapperComponent } from '@app/features/ebill/billercertificate/certificatepanel-wrapper.component';


export function requiredMessage(err, field) {
    let translated = getTranslation("common_all_form_validation_message_required");
    let errorMessage = '';
    errorMessage = field.templateOptions.label;
    return `${errorMessage} ` + translated;
}

export function minlength(err) {
    let firstPart = getTranslation("Should have at least");
    let secondPart = getTranslation("characters");
    return firstPart + ` ${err.requiredLength}` +  secondPart;
}

export function multicheckbox(control) {
    if (control.value) {
        for (let key in control.value) {
            if (control.value[key] === true) {
                return null;
            }
        }
    }
    return { required: true };
}

export function Extras(field) {
    return (field.formState.submitted || field.formControl.touched) && !field.formControl.valid;
}

export function invalidEmailAddress() {
    return getTranslation("common_all_form_validation_message_invalid_email");
}

export function maxLengthExceed(){
    return getTranslation("common_all_form_validation_message_maximum_length");
}

export function passwordNotMatching(){
    return getTranslation("common_all_form_validation_message_password_not_matching");
}
export function floatNumberChecking(){
    return getTranslation("invoice_assignment_invalidamount");
}
export function numberChecking(){
    return getTranslation("common_all_form_validation_message_invalid_number");
}
export function invalidFax(){
    return getTranslation("common_all_form_validation_message_invalid_fax_number");
}
export function invalidPhoneNumber(){
    return getTranslation("common_all_form_validation_message_invalid_phone_number");
}
export function invalidAlphabetsWithSpace(){
    return getTranslation("common_all_form_validation_message_alphabets_space_only");
}
export function invalidAlphabets(){
    return getTranslation("common_all_form_validation_message_alphabets_only");
}
export function invalidAlphabetsWithSpaceAndNumber(){
    return getTranslation("common_all_form_validation_message_invalid_zip_code");
}
export function invalidPassword(){
    return getTranslation("common_all_form_validation_message_follow_password_guidelines");
}
export function invalidDate(){
    return getTranslation("common_all_form_validation_message_invalid_date");
}
export function invalidJson(){
    return getTranslation("common_all_form_validation_message_invalid_json");
}
export function numberValidator(){
    let translated = getTranslation("common_all_form_validation_message_invalid_number");
    return translated;
}
export function invalidInputPercent(){
    let translated = getTranslation("common_all_form_validation_message_invalid_InputPercent");
    return translated;
}
export function companyName(){
    let translated = getTranslation("companyname");
    return translated;
}

export function getTranslation(phrase) {
    let langData = localStorage.getEncryptedItem('lang_data');
    let retVal = "";
    if (langData) {
        let parsedData = JSON.parse(langData);
        retVal = parsedData && parsedData[phrase] ? parsedData[phrase] : phrase;
        return retVal;
    }
    return phrase;
}

export const NgFormlyConfig: ConfigOption = {
    /* types: [
        { name: 'repeatSection', component: RepeatComponent },
        { name: 'multicheckbox', component: FormlyFieldMultiCheckbox },
        { name: 'multiselect', component: FormlyFieldMultiSelect },
        { name: 'file', component: FormlyFieldFile },
    ],
    
    wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
      ], */
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
        { name: 'panelCertificate',component:certificatePanelWrapperComponent},
      ],
    validators: [
        { name: 'required', validation: Validators.required },
        { name: 'mcbRequired', validation: multicheckbox }
    ],
    validationMessages: [
        { name: 'required', message: requiredMessage },
        { name: 'invalidEmailAddress', message: invalidEmailAddress },
        { name: 'maxlength', message: maxLengthExceed },
        { name: 'minlength', message: minlength },
        { name: 'not_matching', message: passwordNotMatching },
        { name: 'invalidFloatNumber', message: floatNumberChecking },
        { name: 'invalidNumber', message: numberChecking },
        { name: 'invalidFax', message: invalidFax },
        { name: 'invalidAlphabetsWithSpace', message: invalidAlphabetsWithSpace },
        { name: 'invalidAlphabets', message: invalidAlphabets },
        { name: 'invalidPhoneNumber', message: invalidPhoneNumber },
        { name: 'invalidAlphabetsWithSpaceAndNumber', message: invalidAlphabetsWithSpaceAndNumber },
        { name: 'invalidPassword', message: invalidPassword },
        { name: 'invalidDate', message: invalidDate },
        { name: 'invalidJson', message: invalidJson },
        { name: 'numberValidator', message: numberValidator },
        { name: 'invalidInputPercent', message: invalidInputPercent },
        { name: 'companyName', message:companyName}
    ],
    extras: { showError: Extras },

};

export const FORMLY_COMPONENTS = [
    /* RepeatComponent,
    FormlyFieldMultiSelect,
    FormlyFieldFile,
    FileValueAccessor */
];