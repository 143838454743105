import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from "@angular/router";
import {config} from '@app/core/smartadmin.config';
import { Observable, Subject, Subscription,BehaviorSubject } from "rxjs";
import { I18nService } from '@app/shared/i18n';
import { CommonService } from '../common/common.service';
import { AppService } from '@app/app.service';

declare var $: any;

const store = {
  smartSkin: localStorage.getItem('sm-skin') || config.smartSkin,
  skin: config.skins.find((_skin) => {
    return _skin.name == (localStorage.getItem('sm-skin') || config.smartSkin)
  }),
  skins: config.skins,
  fixedHeader: localStorage.getItem('sm-fixed-header') == 'true',
  fixedNavigation: localStorage.getItem('sm-fixed-navigation') == 'true',
  fixedRibbon: localStorage.getItem('sm-fixed-ribbon') == 'true',
  fixedPageFooter: localStorage.getItem('sm-fixed-page-footer') != 'true',
  insideContainer: localStorage.getItem('sm-inside-container') == 'true',
  rtl: localStorage.getItem('sm-rtl') == 'true',
  menuOnTop: localStorage.getItem('sm-menu-on-top') == 'true',
  colorblindFriendly: localStorage.getItem('sm-colorblind-friendly') == 'true',

  shortcutOpen: false,
  isMobile: (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase())),
  device: '',

  mobileViewActivated: false,
  menuCollapsed: false,
  menuMinified: false,
};

@Injectable()
export class LayoutService {
  isActivated: boolean;
  smartSkin: string;
  // db = new Localbase('db')
  store: any;
  topNavData: any;
  private subject: Subject<any>;

  private appSubject: Subject<any>;
  private creditSubject: BehaviorSubject<any>;
  private creditChangeEvent: any;

  private profilePhotoSubject: BehaviorSubject<any>;
  private profilePhotoChangeEvent: any

  private darkThemeSubject: BehaviorSubject<any>;
  private darkThemeChangeEvent: any

  private dashboardEnableSortableDrag = new BehaviorSubject<boolean>(true);
  private invoiceEnableSortableDrag = new BehaviorSubject<boolean>(true);
  private purchaseSortableDrag = new BehaviorSubject<boolean>(true);
  private contractSortableDrag = new BehaviorSubject<boolean>(true);
  navId = '';
  navPath = '';
  
  trigger() {
    // this.processBody(this.store);
    this.subject.next(this.store);
    return this.store;
  }

  subscribe(next, err?, complete?) {
    return this.subject.subscribe(next, err, complete)
  }

  subscribeAppDataChange(next, err?, completed?) {
    return this.appSubject.subscribe(next, err, completed);
  }

  triggerAppDataChange(data) {
    this.appSubject.next(data);
  }

  publishCredits(totalCredits) {
    this.creditSubject.next(totalCredits);
  }

  publishProfilePhoto(profilePhoto) {
    this.profilePhotoSubject.next(profilePhoto);
  }

  subscribeCreditsChange(next, err?, complete?) {
    return this.creditChangeEvent.subscribe(next, err, complete);
  }

  subscribeProfilePhoto(next, err?, complete?) {
    return this.profilePhotoChangeEvent.subscribe(next, err, complete);
  }

  darkThemeActivated(status) {
    this.darkThemeSubject.next(status);
  }
  subscribedarkThemeActivated(next, err?, complete?) {
    return this.darkThemeChangeEvent.subscribe(next, err, complete);
  }

  constructor(private i18nService: I18nService,private commonService:CommonService,private _appService:AppService,private router: Router) {
    this.subject = new Subject();
    this.appSubject = new Subject();

    this.creditSubject = new BehaviorSubject<any>({});
    this.creditChangeEvent = this.creditSubject.asObservable();

    this.profilePhotoSubject = new BehaviorSubject<any>({});
    this.profilePhotoChangeEvent = this.profilePhotoSubject.asObservable();

    this.darkThemeSubject = new BehaviorSubject<any>({});
    this.darkThemeChangeEvent = this.darkThemeSubject.asObservable();

    this.store = store;
    this.dumpStorage();
    let data = this.trigger();

    // Observable.fromEvent(window, 'resize').debounceTime(100).map(() => {
    //   let data = this.trigger()
    // }).subscribe()
  }


  onSmartSkin(skin) {
    this.store.skin = skin;
    this.store.smartSkin = skin.name;
  }


  onFixedHeader() {
    this.store.fixedHeader = !this.store.fixedHeader;
    if (this.store.fixedHeader == false) {
      this.store.fixedRibbon = false;
      this.store.fixedNavigation = false;
    }
  }


  onFixedNavigation() {
    this.store.fixedNavigation = !this.store.fixedNavigation;
    if (this.store.fixedNavigation) {
      this.store.insideContainer = false;
      this.store.fixedHeader = true;
    } else {
      this.store.fixedRibbon = false;
    }
  }


  onFixedRibbon() {
    this.store.fixedRibbon = !this.store.fixedRibbon;
    if (this.store.fixedRibbon) {
      this.store.fixedHeader = true;
      this.store.fixedNavigation = true;
      this.store.insideContainer = false;
    }
  }


  onFixedPageFooter() {
    this.store.fixedPageFooter = !this.store.fixedPageFooter;
  }


  onInsideContainer() {
    this.store.insideContainer = !this.store.insideContainer;
    if (this.store.insideContainer) {
      this.store.fixedRibbon = false;
      this.store.fixedNavigation = false;
    }
  }


  onRtl() {
    this.store.rtl = !this.store.rtl;
  }


  onMenuOnTop() {
    this.store.menuOnTop = !this.store.menuOnTop;
  }


  onColorblindFriendly() {
    this.store.colorblindFriendly = !this.store.colorblindFriendly;
  }

  onCollapseMenu(value?) {
    if (typeof value !== 'undefined') {
      this.store.menuCollapsed = value
    } else {
      this.store.menuCollapsed = !this.store.menuCollapsed;
    }
    this.trigger();
  }


  onMinifyMenu() {
    this.store.menuMinified = !this.store.menuMinified;
    this.trigger();
  }

  onShortcutToggle(condition?: any) {
    if (condition == null) {
      this.store.shortcutOpen = !this.store.shortcutOpen;
    } else {
      this.store.shortcutOpen = !!condition;
    }
  }


  dumpStorage() {
    localStorage.setItem('sm-skin', this.store.smartSkin);
    localStorage.setItem('sm-fixed-header', this.store.fixedHeader);
    localStorage.setItem('sm-fixed-navigation', this.store.fixedNavigation);
    localStorage.setItem('sm-fixed-ribbon', this.store.fixedRibbon);
    localStorage.setItem('sm-fixed-page-footer', this.store.fixedPageFooter);
    localStorage.setItem('sm-inside-container', this.store.insideContainer);
    localStorage.setItem('sm-rtl', this.store.rtl);
    localStorage.setItem('sm-menu-on-top', this.store.menuOnTop);
    localStorage.setItem('sm-colorblind-friendly', this.store.colorblindFriendly);
  }

  // factoryReset() {
  //   this.notificationService.smartMessageBox({
  //     title: "<i class='fa fa-refresh' style='color:green'></i>"+ " " + this.i18nService.getTranslation("setting_lookandfeel_ClearLocalStorage"),
  //     content: this.i18nService.getTranslation("common_all_footer_clearcache_message"),
  //     buttons: '['+this.i18nService.getTranslation("common_no")+']['+this.i18nService.getTranslation("common_yes")+']'
  //   }, (ButtonPressed) => {
  //     if ((ButtonPressed == "Yes" || ButtonPressed == "Ja" || ButtonPressed == "Oui") && localStorage) {
  //       var formData = new FormData();
  //       formData.append("companyId", localStorage.getEncryptedItem('companyId'));
  //       formData.append('userId', localStorage.getEncryptedItem('loginId'));
  //       formData.append('appUserId', localStorage.getEncryptedItem('appUserId'));
  //       formData.append('action','Clear');
  //       formData.append("loggedInTime", localStorage.getEncryptedItem("loggedInTime"));
  //       formData.append("lastRequestTime", localStorage.getEncryptedItem("lastRequestTime"));
  //       let objectCount = this.commonService.createObject(formData)
  //       let tokenCount = this.commonService.createToken(objectCount)
  //       let encryptedTokenCount = this.commonService.encrypt(tokenCount)
  //       let encrDataCount = new FormData()
  //       encrDataCount.append("encrToken", encryptedTokenCount);
  //       this._appService.clearLoginTime(encrDataCount).subscribe(data => { var formData = new FormData();
  //         if(!data.status && data.isUnauthenticated) {
  //           this.authenticationService.logout();
  //           this.router.navigate(['/auth/login']);
  //           return false;
  //         }
  //         localStorage.setEncryptedItem("lastRequestTime",data.RESPONSETIME);
  //         let instance = localStorage.getEncryptedItem('instance');
  //         localStorage.clear();
  //         this.db.collection('resCollections').delete();
  //         localStorage.setEncryptedItem('instance', instance);
  //         location.reload();
  //     });
  //     }
  //   });
  // }


  // processBody(state) {
  //   if (state.fixedNavigation != "" && state.fixedNavigation != undefined) {
  //     this.store.fixedNavigation = state.fixedNavigation;
  //   }
  //   else {
  //     this.store.fixedNavigation = false;
  //     this.store.fixedHeader = false
  //   }
  //   if (state.fixedHeader != "" && state.fixedHeader != undefined) {
  //     this.store.fixedHeader = state.fixedHeader;
  //   }
  //   else {
  //     this.store.fixedHeader = false
  //   }
  //   if (state.fixedRibbon != "" && state.fixedRibbon != undefined) {
  //     this.store.fixedRibbon = state.fixedRibbon;
  //   }
  //   else {
  //     this.store.fixedRibbon = false;
  //   }
  //   if (state.menuOnTop != "" && state.menuOnTop != undefined) {
  //     this.store.menuOnTop = state.menuOnTop;
  //   }
  //   else {
  //     this.store.menuOnTop = false;
  //   }
  //   if (state.smartSkin != "" && state.smartSkin != undefined) {
  //     this.store.smartSkin = state.smartSkin;
  //   }
  //   else {
  //     this.store.smartSkin = config.smartSkin;
  //   }
  //   this.darkThemeActivated((store.smartSkin.indexOf('darktheme') >= 0) || (store.smartSkin.indexOf('smart-style-6') >= 0))
  //   let $body = $('body');
  //   let $html = $('html');
  //   $body.removeClass(this.store.skins.map((it) => (it.name)).join(' '));
  //   $body.addClass(this.store.smartSkin);
  //   $("#logo img").attr('src', this.store.skin.logo);
  //   $body.toggleClass('fixed-header', this.store.fixedHeader);
  //   $body.toggleClass('fixed-navigation', this.store.fixedNavigation);
  //   $body.toggleClass('fixed-ribbon', this.store.fixedRibbon);
  //   $body.toggleClass('fixed-page-footer', this.store.fixedPageFooter);
  //   $body.toggleClass('container', this.store.insideContainer);
  //   $body.toggleClass('smart-rtl', this.store.rtl);
  //   $body.toggleClass('menu-on-top', this.store.menuOnTop);
  //   $body.toggleClass('colorblind-friendly', this.store.colorblindFriendly);
  //   $body.toggleClass('shortcut-on', this.store.shortcutOpen);


  //   this.store.mobileViewActivated = $(window).width() < 979;
  //   $body.toggleClass('mobile-view-activated', this.store.mobileViewActivated);
  //   if (this.store.mobileViewActivated) {
  //     $body.removeClass('minified');
  //   }

  //   if (this.store.isMobile) {
  //     $body.addClass("mobile-detected");
  //   } else {
  //     $body.addClass("desktop-detected");
  //   }

  //   if (this.store.menuOnTop) $body.removeClass('minified');


  //   if (!this.store.menuOnTop) {
  //     $html.toggleClass("hidden-menu-mobile-lock", this.store.menuCollapsed);
  //     $html.toggleClass("hidden-menu", this.store.menuCollapsed);
  //     $body.removeClass("minified");
  //   } else if (this.store.menuOnTop && this.store.mobileViewActivated) {
  //     $html.toggleClass("hidden-menu-mobile-lock", this.store.menuCollapsed);
  //     $body.toggleClass("hidden-menu", this.store.menuCollapsed);
  //     $body.removeClass("minified");
  //   }

  //   if (this.store.menuMinified && !this.store.menuOnTop && !this.store.mobileViewActivated) {
  //     $body.addClass("minified");
  //     $body.removeClass("hidden-menu");
  //     $html.removeClass("hidden-menu-mobile-lock");
  //   }
  //   this.dumpStorage();
  // }
  setNavId(navId,navPath)
  {
    this.navId = navId;
    this.navPath = navPath;
  }
  getNavId()
  {
    var pathObj ={
      navId:this.navId,
      navPath:this.navPath
    }
    return pathObj;
  }
}
