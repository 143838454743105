import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { RoleResolveService } from "./core/resolver/role-resolve.service";

const routes: Routes = [
  {
		path: "", redirectTo: 'login', pathMatch: 'full',
	},
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle:"" },
    children: [
      {
        path: "", redirectTo: 'edi', pathMatch: 'full',
      },   
      {
        path: "edi",
        loadChildren: () => import('./features/edi/edi.module').then(m => m.EdiModule),
        data: { pageTitle: "Edi", right: 'bexio.contacts.' },
        canActivate: [AuthGuard],
        // resolve:{
        //   dashboard: RoleResolveService
        // }
      } , 
      {
        path: "ebill",
        loadChildren: () => import('./features/ebill/ebill.module').then(m => m.EbillModule),
        data: { pageTitle: "Ebill",right: 'ebill.settings.'},
        canActivate: [AuthGuard],
        // resolve:{
        //   dashboard: RoleResolveService
        // }
      }, 
    ]
  },
  {
    path: "login",
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    data: { pageTitle: "Login" },
  },
  {
    path: "login/:path",
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    data: { pageTitle: "Login" },
  },
  {
    path: "login/code/:code",
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    data: { pageTitle: "Login" },
  },
  {
    path: 'landing/:token/:partyAccount/:isNewCompany',
    loadChildren:()=>import('./features/landing/landing.module').then(m=>m.LandingModule)
  },  
  // {
  //   path: "superadmin",
  //   loadChildren: () => import('./features/superadmin/superadmin.module').then(m => m.SuperadminModule),
  //   // data: { pageTitle: "Superadmin" },
  // },
  // {
  //   path: "confirmebill/:id",
  //   loadChildren: () => import('./features/confirmebill/confirmebill.module').then(m => m.ConfirmebillModule),
  //   data: { pageTitle: "confirmebill" },
  // },
  {
    path: "10017bf4-ec9f-4071-a94a-a4fee43963ef/:id",
    loadChildren: () => import('./features/superadmin/superadmin.module').then(m => m.SuperadminModule),
    // data: { pageTitle: "Superadmin" },
  },
  { path: "**", redirectTo: "login"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
