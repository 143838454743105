<header id="header" [ngClass]="headerColor ? 'colorChangeTest' : 'theme-bg-color' ">
    <div id="logo-group">
        <span id="logo"></span>
    </div>
    <div class="collapseMenu menu-left-item">
        <sa-collapse-menu></sa-collapse-menu>
    </div>
    <div class="topMenuLeft" (click)="loadEdiMenu()" [ngClass]="ediMenuSelected?'menuSelected':''" title="{{'common_edi' |i18n}}">
        <div class="topMenuItem" >
            <img src="assets/img/iconSvg/edi.png" alt="edi" style="width: 35px; margin-right: 1rem!important;"><span class="commonMenuItem">{{'common_edi' |i18n}}</span>
        </div>
    </div>
    <div class="topMenuLeft" (click)="loadEbillMenu()" [ngClass]="ebillMenuSelected?'menuSelected':''" *ngIf="haseBillPermission" title="{{'common_ebill' |i18n}}">
         <div class="topMenuItem">
            <img src="assets/img/iconSvg/ebill.png" alt="eBill" style="width: 35px; margin-right: 1rem!important;"><span class="commonMenuItem">{{'common_ebill' |i18n}}</span>
        </div>
    </div> 
    <div class="pull-right">
        <div class="header-menu d-flex flex-row justfy-content-start flex-wrap flex-md-nowrap">
            <div class="menu-item menu-item_pading">
                <div class="text-white p-2">
                    <div class="billing-cycle model-7">
                        <span class="showDarkIcon" title="{{'header_dark' | i18n}}"><i class="fas fa-moon darkHeaderIcon"></i></span>
                        <!-- <span class="theme-header-text-color">{{'header_dark' | i18n}}</span> -->
                        <div class="mx-3 checkbox mb-2">
                            <input type="checkbox" id="themeStyle" (click)="onSelectTheme($event)">
                            <label></label>
                        </div>
                        <span class="showLightIcon" title="{{'header_light' | i18n}}"><i class="fas fa-sun lightHeaderIcon"></i></span>                       
                        <!-- <span class="theme-header-text-color">{{'header_light' | i18n}}</span> -->
                    </div>
                </div>
            </div>
            <div class="menu-item" title="{{'common_header_language' | i18n}}" >
                <sa-language-selector></sa-language-selector>
            </div>
            <div class="menu-item" title="{{'common_fullscreen' | i18n}}" [ngClass]="fullScreenSelected?'':'menuSelected'">
                <sa-full-screen (onFullScreenSelect)="selectFullScreen($event)"></sa-full-screen>
            </div>
            <!-- <div class="menu-item" [ngClass]="activitySelected?'menuSelected':''">
                <sa-activities (onActivitySelect)="selectActivity($event)"  [activityType]="'message'"></sa-activities>
            </div>
            <div class="menu-item"  [ngClass]="notificationSelected?'menuSelected':''">
                <sa-activities (onActivitySelect)="selectActivity($event)" [activityType]="'notification'"></sa-activities>                
            </div> -->
            <!-- <div class="menu-item no-hover">
                <img src="assets/img/iconSvg/39-Notification.svg" class="svgImageIcons" alt="">
            </div> -->
            <div class="menu-item webcomponent-option">
            <span style="padding: 6px 20px;" class="webComponetsBorder webComponetsHoverColor">
                <div id="webComponetsOptions" style="padding: 6px 0px 0px 0px" ></div>
            </span>
            
        </div>
        <div class="menu-item webcomponent-profile" >
            <span style="padding: 2px 20px 6px;display: flex;align-items: center">
                <div class="">
                    <div class="avatar">
                        <div id="webComponetsProfile"></div>
                        <!-- <div id="webComponetsProfileLight"></div> -->
                        
                    </div>
                </div>
                <!-- <i class="fa fa-angle-down pull-right theme-icon"></i> -->
            </span>
        </div>
        </div>
        <!-- <ul class="headerItems">
            <li class="ml-2 mr-2">
                <div class="text-white p-2">
                    <div class="billing-cycle model-7">
                        <span>Dark</span>
                        <div class="mx-3 checkbox mb-2">
                            <input type="checkbox" (click)="onSelectTheme($event)">
                            <label></label>
                        </div>
                        <span>Light</span>
                    </div>
                </div>
            </li>
            <li class="ml-2 mr-4 mb-2">
                <sa-language-selector></sa-language-selector>
            </li>
            <li class="ml-2 mr-4">
                <sa-full-screen></sa-full-screen>
            </li>
            <li class="ml-2 mr-4">
                <sa-activities></sa-activities>
            </li>
            <li class="ml-2 mr-4">
                <img src="assets/img/iconSvg/39-Notification.svg" class="svgImageIcons" alt="">
            </li>
            <li class="ml-2 mr-4">
                <div id="webComponetsOptions" style="padding: 9px 0px 0px 0px" ></div>
            </li>
            <li class="ml-2 profileIconHead">
                <div class="">
                    <div class="avatar">
                        <div id="webComponetsProfile">
                        </div>
                    </div>
                </div>
                <i class="fa fa-angle-down pull-right" style="color: white;"></i>
            </li>
        </ul> -->
        
    </div>
</header>
<div class="border-green"></div>
<!-- <div class="dropdown-list dropdownOptions" >
    <div class="dropdown-list-items" *ngIf="settingsMenu">
        <div class="dropdown-list-items-contents">
            <ul class="list-group dropdown-list-row" id="top-list-menu" >
                <li class="list-group-item">
					<div><i class="theme-header-text-color fa fa-users"></i></div>
					{{'Menu A' | i18n}}
				</li>
            </ul>
        </div>
    </div>
</div> -->

<div bsModal #newBuild="bs-modal" class="modal fade mdModal serviceModel" id="lgModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content interConnContent">
            <div class="crossDiv" >
                <span class="modal-title" id="" style="font-size: 16px;font-weight: bold;">{{'newversion_available' | i18n}}</span>
                  <div class="pull-right closeModal" (click)="newBuild.hide()">
                          <a >
                              <i class="fa fa-close crossBtn" aria-hidden="true"></i>
                          </a>
                      </div>
                  </div>
                  <div class="row  pl-4 pr-4 mr-0 mt-4">
                    <div class="pl-4" style="font-size: 15px" [innerHTML]="buildAlert"></div>                     
                    <div class="row w-100 mb-4 justify-content-end mt-4">
                        <button  class="btn-primary btnModalSave text-center floatRight" title="{{'common_clearcache_build' | i18n}}" (click)="clearCache()">{{'common_clearcache_build' | i18n}}</button>
                    </div>
              </div>     
             
          </div>
      </div>
  </div>
  <div bsModal #previewSearchModal="bs-modal" class="modal fade smModal" id="preview" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="configB">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content payContent codePopup" style="height: auto;">
            <div class="payHead">
                <span class="modal-title editPaymentHeader ml-0" id="exampleModalLabel">{{pdfFileName}}</span>
                    <div class="pull-right closeModals closeModal-align">
                        <a (click)="onClickNewWindow()" title="{{'common_open_in_new_tab' | i18n}}"> 
                            <i class="fas fa-external-link-alt externalBtn" aria-hidden="true"></i>
                         </a>
                    </div>
            </div>
            <div class="modal-body modalBody payBody">
                <div class="row mx-0">
                    <div class="modal-body">
                        <iframe [src]=imageData frameBorder="0" scrolling="auto"width="100%" height="680px" *ngIf="!isXml"></iframe>
                        <textarea id="editor" *ngIf="isXml">{{xmlFile}}</textarea>
                    </div>
                </div>
                <div class="d-flex py-0" style="justify-content: space-between;"> 
                    <button class="iom_btn btnRemindLater pdfModalBtn tex-right" title="{{'common_remindMeLater' | i18n}}" (click)="contractRemindLater()"><i class="fa"
                        [ngClass]="{' fa-spinner fa-spin': isRemindLoading}"></i>{{'common_remindMeLater' | i18n}}</button>         
                    <button class="iom_btn btnPdfSave pdfModalBtn" title="{{'common_accept' | i18n}}" [disabled]="ispreviewLoading" (click)="saveContractDetails()"><i class="fa"
                        [ngClass]="{' fa-spinner fa-spin': ispreviewLoading}"></i>{{'common_accept' | i18n}}</button>
                    <!-- <a class="iom_btn btnPdfSave pdfModalBtn" title="{{'common_accept' | i18n}}" (click)="saveContractDetails()" [disabled]="ispreviewLoading"><i class="fa"
                        [ngClass]="{' fa-spinner fa-spin': ispreviewLoading}"></i>{{'common_accept' | i18n}}</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-loading [show]="showRefreshLoader" [config]="{ animationType: ngxLoadingAnimationTypes.circleSwish,backdropBackgroundColour:'transparent'}"
[template]="loadingTemplate"></ngx-loading>
<ngx-loading [show]="showPreviewLoader" [config]="{primaryColour: 'black', animationType: ngxLoadingAnimationTypes.circleSwish,backdropBackgroundColour:'transparent'}"
[template]="loadingTemplate"></ngx-loading>
<!--------------------------------- start common alert modal --------------------------------------------->

    <div id="overlay" *ngIf="showsuccessAlertContract">
    <div  *ngIf="isSuccess" class="p-6 successDiv border mr-3 ml-5"  style="font-size: 20px;margin-top: 278px;z-index: 9999;" >
        <div class="crossbtnDiv">
            <div class="pull-right closecodesDiv" (click)="closeSuccessContract()">
                <a ><i
                        class="fa fa-close crossButton"
                        aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="row successImg">
            <img src="/assets/img/iconSvg/Path 123.svg" class="visa_logo">
        </div>
        <div class="row sucessData" *ngIf = "showErrorContractMsg">
            <span class="theme-content-color" style="font-size: 16px; text-align: center;">{{alertMessage| i18n}}</span>
        </div>
         <div class="pull-right">
            <button class="iom_btn"  (click)="goToAdmin()" title="{{'dashboard_see_contract' | i18n}}">{{'dashboard_go_to_contract'| i18n}}</button>
        </div> 
    </div>  
    <div  *ngIf="isErrors" class="p-6 successDiv border mr-3 ml-5"  style="font-size: 20px;margin-top: 278px;" >
        <div class="crossbtnDiv">
            <div class="pull-right closecodesDiv" (click)="isError=false">
                <a ><i
                        class="fa fa-close crossButton"
                        aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="row failImg">
            <img src="/assets/img/error1.png" style="height: 75px; width: 75px;" class="visa_logo" alt="error.png">
        </div>
        <div class="row sucessData">
            <span class="theme-content-color" style="font-size: 16px; text-align: center;" >{{alertMessage| i18n}}</span>
        </div>
    </div>  
</div>
 
<!--------------------------------- end common alert modal --------------------------------------------->