

import {NgModule, ModuleWithProviders} from "@angular/core";
import {CommonModule} from "@angular/common";
// import {LoginInfoComponent} from "./login-info/login-info.component";
import { I18nModule } from "../i18n/i18n.module";
import { ClearCacheComponent } from "./clearCache/clearCache.component";
import { BootstrapModule } from "@app/shared/bootstrap.module";

@NgModule({
  imports: [CommonModule, I18nModule, BootstrapModule],
  declarations: [ClearCacheComponent],
  exports: [ClearCacheComponent]
})
export class UserModule{}
