import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { CommonService } from '@app/core/services/common.service';
@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls:['footer.component.scss']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  accountsUrl = environment.ACCOUNTS_URL;
  constructor(private commonService: CommonService) {}

  ngOnInit() {
    let style =  this.commonService.getCookie(this.commonService.encrypt('styleCookie'));
    let styleTheme = this.commonService.decrypt(style);
    console.log(styleTheme);
    
  }

}
