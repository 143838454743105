import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TutorialEventsService {
  private user = new BehaviorSubject<any>(false);
  private tutorial = new BehaviorSubject<any>(false);
  cast = this.user.asObservable();
  cast2 = this.tutorial.asObservable();
  constructor() { }

  editUser(newuser){
    this.user.next(newuser);
  }

  chageData(newStep){
    this.tutorial.next(newStep);
  }

}
