import { Injectable, EventEmitter, Output } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EmitService {

  private _emitter;
  private _emitter_removegrpmember;
  public previousUrl;
  //To readjust table
  private readjustDataTableSubject: BehaviorSubject<any>;
  private readjustDataTableEvent: any;
  refreshWizardDataSubjectEbill:  BehaviorSubject<any>;;
  //To refresh table
  private refreshDataTableSubject: BehaviorSubject<any>;
  private refreshDataTableEvent: any;
  @Output() langLoginChange: EventEmitter<any> = new EventEmitter();
  @Output() reloadDygraph: EventEmitter<any> = new EventEmitter();
  checkTutorialDoneSubject: BehaviorSubject<any>;
  checkTutorialDoneEvent: any;
  updateCountsSubject: BehaviorSubject<any>;
  updateCountsEvent: any;
  refreshWizardEventEbill: any;
  checkInactiveInterFaceSubject: BehaviorSubject<any>;
  newDistSubject = new BehaviorSubject<any>(false);
  newDistEvent = this.newDistSubject.asObservable();
  eBillModuleSub = new BehaviorSubject<any>(false);
  eBillModuleEvent = this.eBillModuleSub.asObservable();
  constructor() {
    this._emitter = new EventEmitter ( );
    this._emitter_removegrpmember = new EventEmitter ( );

    this.readjustDataTableSubject = new BehaviorSubject<any>({});
    this.readjustDataTableEvent = this.readjustDataTableSubject.asObservable();

    this.refreshDataTableSubject = new BehaviorSubject<any>({});
    this.refreshDataTableEvent = this.refreshDataTableSubject.asObservable();

    this.checkTutorialDoneSubject = new BehaviorSubject<any>(false);
    this.checkTutorialDoneEvent = this.checkTutorialDoneSubject.asObservable();

    this.updateCountsSubject = new BehaviorSubject<any>(false);
    this.updateCountsEvent = this.updateCountsSubject.asObservable();

    this.refreshWizardDataSubjectEbill = new BehaviorSubject<any>(false);
    this.refreshWizardEventEbill = this.refreshWizardDataSubjectEbill.asObservable();
  }

  subscribeReadjustDataTable(next, err?, complete?){
    return this.readjustDataTableEvent.subscribe(next, err, complete);
  }

  publishReadjustDataTable(data){
    this.readjustDataTableSubject.next(data);
  }

  subscribeRefreshDataTable(next, err?, complete?){
    return this.refreshDataTableEvent.subscribe(next, err, complete);
  }

  publishRefreshDataTable(data){
    this.refreshDataTableSubject.next(data);
  }

  subscribeToServiceEmitter ( callback ) {
    return this._emitter.subscribe ( b => callback ( b ) );
  }

  removemeberfrmGrp ( callback ) {
    return this._emitter_removegrpmember.subscribe ( b => callback ( b ) );
  }
  
  removeMemberFrmGrp ( data ) {
    this._emitter_removegrpmember.emit ( data );
  }

  emitThisData ( data ) {
    this._emitter.emit ( data );
  }
  emitLoginLanguageChange ( data ) {
    this.langLoginChange.emit (data );
  }
  emitPreviosUrl(url){
    this.previousUrl=url;
  }
  emitReloadDygraph ( data ) {
    this.reloadDygraph.emit (data );
  }

  subscribeTutorialStatus(data){
    this.checkTutorialDoneSubject.next(data);
  }

  subscribeUpdateCounts(data){
    this.updateCountsSubject.next(data);
  }
  subscribeInterfaceStatus(data){
    this.checkInactiveInterFaceSubject.next(data);
  }
  subscribeRefreshWizardEbill(data){
    this.refreshWizardDataSubjectEbill.next(data);
  }
}