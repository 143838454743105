import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { NumberPipe } from './number.pipe';
import { CountryFilterPipe } from './country-filter.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe, MomentPipe, NumberPipe, CountryFilterPipe],
  exports: [FieldFilterPipe, MomentPipe, NumberPipe,CountryFilterPipe]
})
export class PipesModule { }
