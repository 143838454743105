import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { routerTransition } from "@app/shared/utils/animations";
import { EmitService } from "@app/ts/services/emit.service";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [],
  animations: [routerTransition]
})
export class MainLayoutComponent implements OnInit {
  constructor(private router: Router,private emitService:EmitService,private _appService:AppService) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        let currentUrl = window.location.href;
        let previousUrl = this.emitService.previousUrl;
        if(currentUrl.includes('edi') ){
          this._appService.notify(false);
        }
        else if(currentUrl.includes('ebill') ){
          this._appService.notify(true);
        }
        // else if(!currentUrl.includes('edi')&&previousUrl.includes('edi')||!currentUrl.includes('ebill')&&previousUrl.includes('ebill')){
        //   this._appService.notify(true);
        // }
        this.emitService.emitPreviosUrl(val['url'])
      }
    });
  }

  ngOnInit() {}

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}
