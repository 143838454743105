import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  apiUrl:string;
 
  _tourData;
  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.API_ACCOUNTS_END_POINT;
   }
   getUserImg(accID){
    return this.httpClient.post(this.apiUrl+`users/fetch-image`,accID)
  }
  getTourListData(data){
    return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/tourList",data).pipe(map((response:Response)=>response))
  }
  updateTour(data){
    return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/updateTour",data).pipe(map((response:Response)=>response))
  }
  setTourData(data){
    this._tourData = data;
  }
  getTourData(){
    return this._tourData;
  }
  updateTutorialStatus(data){
    return this.httpClient.post(environment.API_END_POINT+"gate2bAPI/updateTutorialStatus",data).pipe(map((response:Response)=>response))

  }
  getPlanData(data){
    return this.httpClient.post(environment.API_END_POINT_ADMIN+'admin/manageSubscriptions',data);
  }
  getCounts(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT+"gate2bAPI/gate2B/counts",data).pipe(map((response:Response)=>response))
  }
  getEBillData(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT+"gate2bAPI/getEbillUsedCount",data).pipe(map((response:Response)=>response))
  }
}
